/**
 * Проверяет валидность идентификатора бронирования.
 *
 * @param {string} bookingId - Идентификатор бронирования для проверки.
 * @returns {boolean} Возвращает true, если идентификатор соответствует шаблону, иначе false.
 */
function validateBookingId(bookingId) {
  const pattern = /^[A-Z0-9]{5}-\d{6}$/;
  return pattern.test(bookingId);
}

/**
 * Читает содержимое файла и возвращает его в виде строки, закодированной в base64.
 *
 * @param {File} file - Файл для чтения.
 * @returns {Promise<string>} Промис, который при успешном завершении возвращает строку в base64.
 */
function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => {
      console.log(file instanceof Blob); // Should be true if the file is a Blob
      console.log(file instanceof File); // Should be true if the file is a File
      console.log(file.size);
      console.log(error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

/**
 * Отправляет запрос к заданному методу API.
 *
 * @param {string} methodName - Название метода для запроса.
 * @param {string} requestMethod - HTTP метод (например, 'GET', 'POST').
 * @param {Object} [requestBody={}] - Тело запроса.
 * @returns {Promise<Object>} Промис с результатом ответа от сервера.
 */
async function fetchByMethod(methodName, requestMethod, requestBody = {}) {
  const options = {
    redirect: "follow",
    method: requestMethod,
    headers: {
      "Content-Type": "text/plain;charset=utf-8",
    },
    muteHttpExceptions: true, // Добавляем опцию muteHttpExceptions
  };
  if (requestMethod !== "GET") {
    options.body = JSON.stringify({ ...requestBody });
  }
  let response = await fetch(config[methodName], options);

  // Check if the response was successful
  if (!response.ok) {
    console.error(`Error: ${response.status}`);
  }
  const data = await response.json();

  if (data.result === "error") {
    return data;
  }

  return data.result;
}

async function sendResultsToPlanfix(data = {}) {
  console.log("sendResultsToPlanfix: ", data);
  const url =
    "https://bbaoq5d1l5sbfs7n2t4s.containers.yandexcloud.net/admin-panel/deposits";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.error(`Error sending data to planfix: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error sending data to planfix: ", error);
  }
}

/**
 * Отправляет GET-запрос к заданному методу API с указанными параметрами запроса.
 *
 * @param {string} methodName - Название метода для запроса.
 * @param {Object} queryParams - Параметры запроса в виде объекта.
 * @returns {Promise<Object>} - Промис с результатом ответа от сервера.
 */
async function fetchYandexByMethod(methodName, queryParams) {
  const url = new URL(config[methodName]);
  Object.keys(queryParams).forEach((key) =>
    url.searchParams.append(key, queryParams[key])
  );

  const response = await fetch(url.toString());
  return await response.json();
}
/**
 * Возвращает текущую дату и время в Московском часовом поясе.
 *
 * @returns {Object} Объект с полями date и time.
 */
function getCurrentMoscowDateTime() {
  const moscowTime = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" })
  );
  return {
    date: `${moscowTime.getDate()}.${
      moscowTime.getMonth() + 1
    }.${moscowTime.getFullYear()}`,
    time: `${moscowTime.getHours()}:${moscowTime.getMinutes()}:${moscowTime.getSeconds()}`,
  };
}
let isRequestInProgress = false;

async function retryOperation(event) {
  // Проверяем, выполняется ли в данный момент запрос
  if (isRequestInProgress) {
    return;
  }
  // Показываем спиннер
  mainSpinner.classList.remove("hidden");

  // Устанавливаем состояние в "запрос выполняется"
  isRequestInProgress = true;

  // Получаем id операции из скрытого поля
  const operationIdInput = event.target.parentNode.parentNode.querySelector(
    "input[type='hidden']"
  );
  if (!operationIdInput) {
    console.error("Не удалось найти скрытое поле с id операции");
    return;
  }
  const operationId = operationIdInput.value;
  // Далее среди операций (operationsData), находим операцию по id (она должна была подгрузиться из YDB
  //  при заходе в список Мои операции)
  const data = localStorage.getItem("operations");
  if (!data) {
    isRequestInProgress = false;
    alert("Не удалось получить данные операций из localStorage");
    throw new Error("Не удалось получить данные операций из localStorage");
  }
  const operationsData = JSON.parse(data);
  const operation = operationsData.find((item) => item.id === operationId);
  if (!operation) {
    isRequestInProgress = false;
    alert(`Не удалось найти операцию с id = ${operationId}`);
    throw new Error(`Не удалось найти операцию с id = ${operationId}`);
  }

  // Выполняем операцию повторно
  try {
    // Если это оплата за доп.услугу, то достаем данные о бронировании (так как нужные данные о доп.услугах)
    let operationBookingData;
    if (operation.operation_type === "income") {
      if (operation.type === "additional-service") {
        try {
          const bookingDataResponse = await fetchBooking(
            operation.hotel_name,
            operation.booking_id
          );
          operationBookingData = bookingDataResponse.result;
          if (operationBookingData) {
            throw new Error("Не удалось получить данные бронирования");
          }
        } catch (error) {
          if (!operationBookingData) {
            showErrorPopup(
              "Не удалось получить данные бронирования",
              operation.booking_id
            );
            mainSpinner.classList.add("hidden");
            isRequestInProgress = false;
            return;
          }
        }
      }
      // Собираем данные в запрос для addPayment и делаем запросы
      await addPayment(
        operation.type,
        +operation.amount,
        operation.bnovo_account,
        operation.dds_account,
        operation.booking_id,
        operation.booking_page_id,
        operation.hotel_name,
        operation.guest_name,
        operation.created_by_email,
        operation.created_by_uid,
        operation.operation_date_time,
        operation.created_by_name,
        // Ещё если это оплата за доп.услугу, то достаем данные этой услуги для запроса и передаем их
        operation.type === "additional-service"
          ? {
              ...operationBookingData.additionalServices[operation.service_id],
              service_id: operation.service_id,
            }
          : undefined
      );
      if (data.result === "error") {
        showErrorPopup(data.message, operation.booking_id);
        mainSpinner.classList.add("hidden");
        isRequestInProgress = false;
        return;
      }
    } else if (operation.operation_type === "refund") {
      // Собираем данные в запрос для addRefund и делаем запросы
      // Генерим id для новой операции
      const unicId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      // Дополучаем актуальные поля arrival, departure, room из биново
      try {
        const bookingDataResponse = await fetchBooking(
          operation.hotel_name,
          operation.booking_id
        );
        operationBookingData = bookingDataResponse.result;
        if (operationBookingData) {
          throw new Error("Не удалось получить данные бронирования");
        }
      } catch (error) {
        if (!operationBookingData) {
          showErrorPopup(
            "Не удалось получить данные бронирования",
            operation.booking_id
          );
          mainSpinner.classList.add("hidden");
          isRequestInProgress = false;
          return;
        }
      }
      // Отправляем запрос на создание операции
      await createInProgressRefundOperation(
        unicId,
        "refund",
        operation.booking_page_id,
        operation.booking_id,
        operation.type,
        operation.guest_name,
        operation.created_by_email,
        operation.created_by_uid,
        operation.hotel_name,
        operation.operation_date_time,
        operation.source_name,
        operation.created_by_name,
        +operation.amount,
        "",
        operation.comment,
        operation.refund_cancel_reason,
        operation.refund_payment_way
      );
      // Отправляем запрос на добавление строки в ДДС
      const data = await addRefundPayment(
        operation.hotel_name,
        operation.created_by_email,
        operation.created_by_uid,
        operation.type,
        operation.created_by_name,
        operation.source_name,
        operation.amount.toString(),
        operation.booking_id,
        operation.booking_page_id,
        operationBookingData.arrival,
        operationBookingData.departure,
        unicId,
        operation.operation_date_time,
        operationBookingData.room,
        operation.refund_payment_way,
        operation.guest_name,
        operation.comment,
        operation.refund_cancel_reason,
        operationBookingData.hotelId
      );
      if (data.result === "error") {
        showErrorPopup(data.message, operation.booking_id);
        mainSpinner.classList.add("hidden");
        isRequestInProgress = false;
        return;
      }
    }
  } catch (error) {
    console.error("Ошибка при повторении операции: ", error);
    showErrorPopup(error.message, operation.booking_id);
    mainSpinner.classList.add("hidden");
    isRequestInProgress = false;
    return;
  }
  // Далее отправляем запрос на Яндекс Функцию чтобы удалить из списка старую операцию
  let deleteOperationData;
  try {
    deleteOperationData = await deleteUserOperation(
      operation.created_by_uid,
      operationId
    );
    if (deleteOperationData.result === "error") {
      showErrorPopup(deleteOperationData.message, operation.booking_id);
      mainSpinner.classList.add("hidden");
      isRequestInProgress = false;
      return;
    }
  } catch (e) {
    console.error("Ошибка при удалении операции: ", e);
    showErrorPopup(data.message, operation.booking_id);
    mainSpinner.classList.add("hidden");
    isRequestInProgress = false;
    return;
  }

  // Скрываем спиннер
  mainSpinner.classList.add("hidden");

  // Выводим уведомление об успешной оплате
  showSuccessPopup(
    `Повторный запрос отправлен. Новая операция создана, статус отображен в списке`
  );

  // Обновляем операции
  await refreshOperations();

  // Устанавливаем состояние в "запрос не выполняется"
  isRequestInProgress = false;
}

/**
 * Обновляет макет операций на странице на основе данных операций.
 *
 * @param {Array} operationsData - Массив объектов операций для отображения
 * @returns {void}
 */
function updateOperationsLayout(operationsData) {
  // Удаляем все строки в tbody
  const tableBody = document.querySelector("#my-operations tbody");
  while (tableBody.firstChild) {
    tableBody.removeChild(tableBody.firstChild);
  }

  // Для каждой операции создаем и добавляем строку в таблицу
  operationsData.forEach((operation) => {
    const row = document.createElement("tr");

    // Создаем и добавляем ячейки с данными операций
    const operationTypeCell = document.createElement("td");
    operationTypeCell.className = "px-6 py-4 whitespace-no-wrap";
    // Добавляем данные в созданные ячейки
    let operationTypeText;
    if (operation.operation_type === "income") {
      operationTypeText = "Приход";
    } else if (operation.operation_type === "refund") {
      operationTypeText = "Возврат";
    } else {
      operationTypeText = "Неизвестный";
    }
    operationTypeCell.textContent = operationTypeText;
    row.appendChild(operationTypeCell);

    const hotelNameCell = document.createElement("td");
    hotelNameCell.className = "px-6 py-4 whitespace-no-wrap";
    // Добавляем данные в созданные ячейки
    hotelNameCell.textContent = operation.full_hotel_name;
    row.appendChild(hotelNameCell);

    const bookingIdCell = document.createElement("td");
    bookingIdCell.className = "px-6 py-4 whitespace-no-wrap";
    bookingIdCell.textContent = operation.booking_id;
    row.appendChild(bookingIdCell);

    const guestNameCell = document.createElement("td");
    guestNameCell.className = "px-6 py-4 whitespace-no-wrap";
    guestNameCell.textContent = operation.guest_name;
    row.appendChild(guestNameCell);

    const typeCell = document.createElement("td");
    typeCell.className = "px-6 py-4 whitespace-no-wrap";
    if (operation.type === "accommodation") {
      typeCell.textContent = "Проживание";
    } else if (operation.type === "deposit") {
      typeCell.textContent = "Залог";
    } else if (operation.type === "additional-service") {
      typeCell.textContent = "Доп. услуга";
    } else {
      typeCell.textContent = operation.type;
    }
    row.appendChild(typeCell);

    const amountCell = document.createElement("td");
    amountCell.className = "px-6 py-4 whitespace-no-wrap";
    amountCell.textContent = operation.amount + " руб";
    row.appendChild(amountCell);

    const operationDateTimeCell = document.createElement("td");
    operationDateTimeCell.className = "px-6 py-4 whitespace-no-wrap";
    const operationDateTime = new Date(operation.operation_date_time);
    operationDateTimeCell.textContent =
      operationDateTime.toLocaleString("ru-RU");
    row.appendChild(operationDateTimeCell);

    const statusCell = document.createElement("td");
    statusCell.className = "px-6 py-4 whitespace-no-wrap";
    const actionCell = document.createElement("td");
    actionCell.className = "px-6 py-4 whitespace-no-wrap";
    if (operation.status === "error") {
      const statusSpan = document.createElement("span");
      statusSpan.className = "status-red-text";
      statusSpan.textContent = "Ошибка";
      statusCell.appendChild(statusSpan);

      // Создаем кнопку "Повторить" справа от статуса
      const retryButton = document.createElement("button");
      retryButton.textContent = "Повторить";
      retryButton.className = "px-2 py-1 bg-blue-500 text-white rounded";
      retryButton.addEventListener("click", async (event) => {
        await retryOperation(event);
      });
      actionCell.appendChild(retryButton);
    } else if (operation.status === "in progress") {
      const statusSpan = document.createElement("span");
      statusSpan.className = "status-blue-text";
      statusSpan.textContent = "В процессе";
      statusCell.appendChild(statusSpan);
    } else if (operation.status === "success") {
      const statusSpan = document.createElement("span");
      statusSpan.className = "status-green-text";
      statusSpan.textContent = "Завершена";
      statusCell.appendChild(statusSpan);
    } else {
      statusCell.textContent = operation.status;
    }
    // Создаем скрытый элемент для хранения id операции
    const operationId = document.createElement("input");
    operationId.type = "hidden";
    operationId.value = operation.id;

    row.appendChild(statusCell);
    row.appendChild(actionCell);
    row.appendChild(operationId);
    // Добавляем строку в tbody таблицы
    tableBody.appendChild(row);
  });
}

/**
 * Обновляет операции пользователя, сортируя их в обратном порядке по дате.
 *
 * @async
 * @function
 * @returns {Promise<void>}
 */
async function refreshOperations() {
  // Получаем данные операций пользователя
  const data = await fetchUserOperations(window.user.uid);

  // Преобразование строки даты в объект Date для каждого элемента массива
  data.forEach((item) => {
    item.operation_date_time = new Date(item.operation_date_time);
  });

  // Сортируем массив в обратном порядке (наиболее недавние в начале)
  data.sort((a, b) => b.operation_date_time - a.operation_date_time);

  // Сохраняем отсортированные данные в локальном хранилище
  localStorage.setItem("operations", JSON.stringify(data));

  // Обновляем счетчик уведомлений
  refreshNotificationsCounter();

  // Обновляем макет операций на странице
  updateOperationsLayout(data);
}
/**
 * Функция refreshNotificationsCounter() обновляет счетчик уведомлений об ошибках.
 */
function refreshNotificationsCounter() {
  const data = localStorage.getItem("operations");
  const readData = localStorage.getItem("readOperationIds");
  if (!data) {
    throw new Error("Не удалось получить данные операций из localStorage");
  }
  const operations = JSON.parse(data);
  const readOperations = JSON.parse(readData) || [];
  // Проверяем найдется ли одно не прочитанное уведомление об ошибке, если да, то показываем круг с уведомлением
  const countOfUnreadErrors = operations.filter(
    (item) => item.status === "error" && !readOperations.includes(item.id)
  ).length;
  const notificationBlock = document.getElementById("notification-block");

  if (countOfUnreadErrors > 0) {
    const notificationCount = countOfUnreadErrors;
    // Обновляем количество уведомлений об ошибках
    const notificationCountElement =
      document.getElementById("notification-count");
    notificationCountElement.textContent = notificationCount;

    // Показываем круг с уведомлением, если есть ошибки, или скрываем его
    notificationBlock.classList.remove("hidden");
  } else {
    notificationBlock.classList.add("hidden");
  }
}
/**
 * Функция toggleMainMenu(state) скрывает или показывает главное меню в зависимости от состояния.
 * @param {string} state - Состояние меню. Если равно 'hide', то меню будет скрыто. В противном случае меню будет показано.
 */
async function toggleMainMenu(state) {
  if (state === "hide") {
    goToMainMenuBtn.classList.remove("hidden");
  } else if (state === "show") {
    goToMainMenuBtn.classList.add("hidden");
  }
  const appElement = document.getElementById("app");
  appElement.classList.remove("hidden");
  const sections = appElement.getElementsByTagName("section");

  for (let i = 0; i < sections.length; i++) {
    if (state === "hide") {
      // Скрываем главное меню, показываем все остальные
      sections[i].id === "select-booking"
        ? sections[i].classList.add("hidden")
        : sections[i].classList.remove("hidden");
    } else if (state === "show") {
      // Скрываем все кроме главного экрана (select-booking)
      sections[i].id === "select-booking"
        ? sections[i].classList.remove("hidden")
        : sections[i].classList.add("hidden");
    }
  }

  try {
    // Обновляем счетчик уведомлений об ошибках (в случае если операции уже были прогружены)
    if (state === "show" && window.user) {
      await refreshOperations();
    }
  } catch (e) {
    console.warn(e);
  }
}
/**
 * Отображает всплывающее окно с сообщением об ошибке.
 *
 * @param {string} message - Сообщение об ошибке.
 * @param {string} bookingId - Идентификатор бронирования, который вызвал ошибку.
 */
function showErrorPopup(message, bookingId) {
  const { date, time } = getCurrentMoscowDateTime();

  // Создаём элементы для popup
  const overlay = document.createElement("div");
  overlay.classList.add(
    "fixed",
    "inset-0",
    "bg-black",
    "bg-opacity-50",
    "z-999"
  );
  const modal = document.createElement("div");
  modal.classList.add(
    "fixed",
    "top-1/2",
    "left-1/2",
    "transform",
    "-translate-x-1/2",
    "-translate-y-1/2",
    "bg-white",
    "p-8",
    "rounded-lg",
    "shadow-xl",
    "z-1000",
    "max-w-xl",
    "w-full"
  );

  modal.innerHTML = `
        <div class="border-b pb-4 mb-4">
            <h2 class="text-red-600 font-bold text-xl">Не удалось создать запрос на операцию</h2>
            <span class="text-gray-600 text-sm">Дата: ${date} | Время: ${time}</span>
        </div>
        <p class="mb-6">${message}</p>
        <p class="mb-4 text-gray-700">Сообщите разработчику. Код бронирования: <span class="font-semibold">${bookingId}</span>.</p>
        <button class="mt-4 px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-150 ease-in-out">Закрыть</button>
    `;

  modal.querySelector("button").addEventListener("click", async () => {
    overlay.remove();
    modal.remove();
    await toggleMainMenu("show"); // Скрываем все кроме главного меню
  });

  document.body.appendChild(overlay);
  document.body.appendChild(modal);
}

/**
 * Отображает всплывающее окно с успешным сообщением.
 *
 * @param {string} message - Сообщение об успешном выполнении.
 */
function showSuccessPopup(message) {
  // Создаём элементы для popup
  const overlay = document.createElement("div");
  overlay.classList.add(
    "fixed",
    "inset-0",
    "bg-green-900",
    "bg-opacity-30",
    "z-999"
  );
  const modal = document.createElement("div");
  modal.classList.add(
    "fixed",
    "top-1/2",
    "left-1/2",
    "transform",
    "-translate-x-1/2",
    "-translate-y-1/2",
    "bg-white",
    "p-8",
    "rounded-lg",
    "shadow-xl",
    "z-1000",
    "max-w-xl",
    "w-full"
  );

  modal.innerHTML = `
        <div class="border-b pb-4 mb-4">
            <h2 class="text-green-600 font-bold text-xl">Успешно</h2>
        </div>
        <p class="mb-6">${message}</p>
        <button class="mt-4 px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-150 ease-in-out">Закрыть</button>
    `;

  modal.querySelector("button").addEventListener("click", async () => {
    overlay.remove();
    modal.remove();
    await toggleMainMenu("show"); // Скрываем все кроме главного меню
    await window.selectBookingListener();
  });

  document.body.appendChild(overlay);
  document.body.appendChild(modal);
}

/**
 * Функция преобразует объект Date в строковый формат YYYY-MM-DD.
 *
 * @param {Date} date - объект Date, который нужно преобразовать.
 * @param {string} delimiter - разделитель между день/месяц/год.
 * @returns {string} дата в формате YYYY-MM-DD или другом, если указан другой разделитель.
 */
function getClassicDateFormat(date, delimiter = "-") {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}${delimiter}${month}${delimiter}${day}`;
}

function loadBookingFromPath() {
  // Разбор параметров запроса из URL
  const urlParams = new URLSearchParams(window.location.search);

  // Получение значений 'hotelId' и 'bookingId'
  const hotelId = urlParams.get("hotelId");
  const bookingId = urlParams.get("bookingId");

  if (bookingId && hotelId) {
    // Установка номера бронирования в поле ввода 'booking-number'
    const bookingNumberInput = app.querySelector("#booking-number");
    if (bookingNumberInput) {
      bookingNumberInput.value = bookingId;
    }
    // Выбор отеля в выпадающем списке с идентификатором 'hotel'
    const hotelSelect = app.querySelector("#hotel");
    if (hotelSelect) {
      const hotelValueByHotelId = Object.keys(config.hotels).find(
        (hotelKey) => +config.hotels[hotelKey].id === +hotelId
      );
      if (hotelValueByHotelId) {
        hotelsSelect.setSelected(hotelValueByHotelId);
        // Запуск события ввода
        window.selectBookingListener();
      }
    }
  }
}
